import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CartContext } from "./layout"
import CartItem from "./cartItem"
import { Link } from "gatsby"

const Cart = ({ _showCart, updateCart }) => {
    const { cart, showCart } = useContext(CartContext)
    const data = useStaticQuery(graphql`
    query Products {
        allProduct{
            nodes{
                name
                category
                price
                offer
                types{
                    size
                    material
                    p
                    s
                }
                priceList{
                    size
                    varients{
                        t
                        p
                    }
                }
                meta {
                    id
                }
            }
        }
    }`)
    let products = data.allProduct.nodes
    let cartItems = []
    let total = 0;
    if (cart) {
        cartItems = cart.map(res => {
            res.product = products.find(p => { return p.meta.id === res.productId })
            let _price = res.product.priceList ? res.product.priceList.find(s => { return JSON.stringify(s.size) === JSON.stringify(res.selectedSize) }).varients.find(v => { return v.t === res.thick }).p : res.product.price
            _price = res.product.types ? res.product.types[res.type].p : res.product.price
            let _offer = res.product.types[res.type].s ? res.product.types[res.type].s : Math.floor(_price - _price * (res.product.offer / 100))
            res.price = _offer.toFixed(2)
            total = total + (parseFloat(res.price) * parseInt(res.quantity))
            return res
        })
    }
    const hideCart = (event) => {
        event.preventDefault();
        if (event.target.classList.contains('cart-backdrop')) {
            showCart()
        }
    }
    return (
        <div className={'cart-backdrop' + (_showCart ? "" : " d-none")} onClick={(e) => hideCart(e)} onKeyDown={(e) => hideCart(e)} role="presentation">
            <div className="cart-wrapper">
                <div className="row d-flex">
                    <div className="col text-center d-flex justify-content-center">
                        <h4>Your Cart</h4>
                        <i className="close-cart bi bi-x-square h4 text-dark" onClick={() => showCart()} onKeyDown={(e) => showCart(e)} role="presentation"></i>
                    </div>
                    <hr />
                    <div className={(cartItems.length ? '' : 'd-none ') + 'cart-items'}>
                        {
                            cartItems.map((item, i) => (
                                <CartItem item={item} index={i} key={i} updateCart={updateCart}></CartItem>
                            ))
                        }
                    </div>
                    <div className={(cartItems.length ? 'd-none ' : '') + 'text-center mt-5'}>
                        <h5>Your cart is empty!</h5>
                        <Link to="/" onClick={() => showCart()}>Choose a suitable mattress for you</Link>
                    </div>
                    <div className={(cartItems.length ? '' : 'd-none ') + 'cart-info p-3 text-light text-center'}>
                        <p>You can add your GST details in the 'Address' section on the next page</p>
                        {/* <select className="form-control">
                            <option>Select Offer</option>
                            <option>Winter - Up To 40% Off</option>
                        </select>
                        <input type="text" className="form-control mt-2" placeholder="Have a Discount Code?" disabled></input> */}
                        <Link to="/checkout" onClick={() => showCart()}>
                            <button className="btn btn-lg btn-warning mt-5 d-flex justify-content-between" style={{ width: '100%' }}>BUY NOW - <strong>₹{total.toFixed(2)}</strong><i className="bi bi-chevron-double-right text-info" style={{ marginLeft: '10px', borderRadius: '50%', backgroundColor: '#fff', width: '32px', height: '32px', marginRight: '-5px' }}></i></button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart